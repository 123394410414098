import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Button, TextField, Card, CardContent, CardMedia, Box, CircularProgress } from '@mui/material';
import { fetchJson, updateRequest } from './common';

function App() {
  const [product, setProduct] = useState(null);
  const [guess, setGuess] = useState('');
  const [points, setPoints] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [url, setUrl] = useState(null);
  const [skip, setSkip] = useState(localStorage.getItem('skip') ? Number(localStorage.getItem('skip')) : 0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const sessionPoints = useRef(0);
  const inputRef = useRef(null);

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const data = await fetchJson(`/api/next?skip=${skip}`);
      setProduct(data);
    } finally {
      setLoading(false);
    }
  };

  const submitGuess = async () => {
    setSubmitting(true);
    try {
      await updateRequest('/api/submit', { id: product.id, guess: parseInt(guess) }, data => {
        sessionPoints.current += data.points;
        setPoints(sessionPoints.current);
        setActualPrice(data.price);
        setUrl(data.url);
        const difference = Math.abs(data.price - guess);
        setMessage(`You guessed ${guess} but the actual price was ${data.price}. You are ${difference} off for ${data.points} points.`);
        localStorage.setItem('skip', skip + 1);
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchProduct();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const handleNextItem = () => {
    setSkip(skip + 1);
    setGuess('');
    setActualPrice(0);
    setUrl(null);
    setMessage('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (message) {
        handleNextItem();
      } else {
        submitGuess();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [message, guess]);

  return (
    <Container style={{ height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : product && (
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '20px' }}>
          <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <CardMedia
              component="img"
              style={{ flex: 1, objectFit: 'contain', minHeight: 0 }}
              image={product.image}
              alt={product.name}
            />
          </Box>
          <CardContent style={{ flexShrink: 0, display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>{product.name}</Typography>
            <Typography variant="subtitle1" gutterBottom>{product.brand}</Typography>
            <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <TextField
                type="number"
                label="Guess the price"
                value={guess}
                onChange={(e) => setGuess(e.target.value)}
                inputProps={{ min: 0, max: 10000000 }}
                fullWidth
                disabled={!!message || submitting}
                inputRef={inputRef}
              />
              <Button 
                variant="contained" 
                onClick={submitGuess} 
                disabled={!!message || submitting}
              >
                {submitting ? <CircularProgress size={24} /> : "Guess"}
              </Button>
            </Box>
            {message && (
              <>
                <Typography variant="h6" gutterBottom>{message}</Typography>
                <Typography variant="h5" color={points > 0 ? "green" : "red"}>{sessionPoints.current} points</Typography>
                <Typography variant="h6">Actual Price: {actualPrice}</Typography>
                {url && <Button variant="contained" href={`https://www.ssense.com/en-us${url}`}>Buy This</Button>}
                <Button variant="contained" onClick={handleNextItem} style={{ marginTop: '10px' }}>Next Item</Button>
              </>
            )}
          </CardContent>
        </Card>
      )}
    </Container>
  );
}

export default App;
